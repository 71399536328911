<template>
    <my-drawer v-model="visible"
               :fullscreen="false"
               :nav-left-arrow="false"
               position="bottom"
               round
               title="添加目的码"
               @close="onClose"
    >
        <template #nav-right>
            <van-icon color="#323233" name="cross" size="18" @click="onClose" />
        </template>
        <div class="CreateSeat">
            <van-form ref="form" @submit="onSubmit">
                <my-item label="电话类型">
                    <radio-button v-model="modelData.seat_num_type" :items="seatNumType"></radio-button>
                </my-item>
                <my-item label="电话号码" required>
                    <van-field v-model="modelData.seat_num"
                               :rules="[
                                   {required: true},
                                   {validator: mobileValidator, message: '请输入正确的大陆手机/固话号码'}
                               ]"
                               placeholder="请输入电话号码"
                               type="number"
                    />
                </my-item>
                <my-item label="持有人姓名">
                    <van-field v-model="modelData.seat_name" placeholder="请输入持有人姓名" />
                </my-item>
                <my-item label="持有人身份证号">
                    <van-field v-model="modelData.seat_card_id"
                               :rules="[
                                   {validator: idValidator, message: '请输入正确的持有人身份证号'}
                               ]"
                               placeholder="请输入持有人身份证号"
                    />
                </my-item>
            </van-form>
        </div>
        <template #footer>
            <van-button block native-type="button" type="default" @click="onClose">取消</van-button>
            <van-button block native-type="button" type="info" @click="$refs.form.submit();">确定</van-button>
        </template>
    </my-drawer>
</template>

<script>
    import {MyDrawer, MyItem} from "@/components/MyPlugins";
    import RadioButton from "@/components/RadioButton";
    import {mapState} from "vuex";
    import {regex} from "@/utils/tools";
    import {MOBILE_REG, TEL_REG, IDCARD_REG} from "@/utils/keys";
    import {numOpenValidatePhone} from "@/api/openOrder";

    export default {
        name: "CreateSeat",
        components: {MyDrawer, MyItem, RadioButton},
        props: {
            params: Object,
            seats: Array
        },
        data() {
            return {
                mobileValidator: regex([MOBILE_REG, TEL_REG]),
                idValidator: regex([IDCARD_REG]),
                visible: false,
                modelData: {
                    seat_num: "",
                    seat_name: "",
                    seat_card_id: "",
                    seat_num_img: "",
                    seat_num_type: "3",
                }
            }
        },
        computed: {
            ...mapState('number', ['seatNumType'])
        },
        methods: {
            onSubmit() {
                let {seat_num, seat_name, seat_card_id} = this.modelData;
                let isRepeat = this.seats.some(v => String(v.seat_num) === String(seat_num));
                if (isRepeat) {
                    this.$toast.fail('目的码不能重复');
                    return;
                }
                let {number, section_code} = this.params;
                let model = {...this.modelData, valid: 0};
                if (seat_num && seat_name && seat_card_id && ['9', '10'].includes(section_code)) {
                    let data = [
                        {batch_name: number, idcard: seat_card_id, mobile: seat_num, name: seat_name}
                    ];
                    this.$toast.loading({message: '三要素验证中，请稍后...', forbidClick: true, duration: 0});
                    numOpenValidatePhone(data).then(res => {
                        let [item] = res.data;
                        this.$toast.clear();
                        model.valid = item.code == 200 ? 1 : 2;
                        if (item.code == 200) {
                            model.seat_num_img = item.url;
                            this.$emit('submit', model);
                            this.onClose();
                        } else {
                            this.$dialog.confirm( {
                                title: '提示',
                                message: '当前目的码未通过三要素验证，是否继续添加？',
                                confirmButtonText: '继续使用',
                                cancelButtonText: '返回修改'
                            }).then(() => {
                                this.$emit('submit', model);
                                this.onClose();
                            }).catch(() => {
                                console.log('cancel');
                            });
                        }
                    }).catch(() => {
                        this.$toast.clear();
                    });
                } else {
                    this.$emit('submit', this.modelData);
                    this.onClose();
                }
            },
            doReset() {
                this.modelData = {
                    seat_num: "",
                    seat_name: "",
                    seat_card_id: "",
                    seat_num_img: "",
                    seat_num_type: "3",
                };
            },
            onClose() {
                this.doReset();
                this.visible = false;
            },
        }
    }
</script>

<style lang="scss" scoped>
    .CreateSeat {
        padding: 20px 16px;
        .MyItem:last-child {
            margin-bottom: 0;
        }
    }
</style>
