<template>
    <div class="Step3">
        <div style="margin-bottom: 16px;">
            <van-button icon="delete-bin-7-line"
                        icon-prefix="ri"
                        native-type="button"
                        size="small"
                        type="default"
                        @click="onClear"
            >全部清空</van-button>
            <van-button native-type="button" size="small" style="margin-left: 10px;" type="info" @click="useLegal">使用法人信息</van-button>
        </div>
        <my-item required>
            <div slot="label">
                <div style="display: flex;">
                    <div>
                        <span style="color: #F56C6C;">* </span>
                        <span>上传经办人证件正反面</span>
                    </div>
                    <my-link style="margin-left: auto;" @click="onOcr">识别证件信息</my-link>
                </div>
                <div style="color: #ADAEAB; font-size: 12px; margin-top: 5px;">
                    支持JPG/PNG/BMP格式，且不能大于4MB，最长边最大长度4096px。如需使用自动识别证件信息功能，请优先上传真实证件照片(仅限身份证)可提高识别成功率。
                </div>
            </div>
            <my-upload v-model="modelData.agent.agent_person_img"
                       :disabled="!modelData.user_code"
                       :params="{type: 1, userCode: modelData.user_code}"
            />
        </my-item>
        <my-item label="经办人姓名" required>
            <van-field v-model="modelData.agent.agent_person_name" :rules="[{required: true}]" placeholder="请输入经办人姓名" />
        </my-item>
        <my-item label="企业电话" required>
            <van-field v-model="modelData.agent.agent_person_mobile"
                       :rules="[{required: true}, {validator: mobileValidator, message: '请输入正确的大陆手机/固话号码'}]"
                       placeholder="请输入企业电话"
            />
        </my-item>
        <my-item label="经办人证件类型">
            <radio-button v-model="modelData.agent.agent_person_type" :items="personType"></radio-button>
        </my-item>
        <my-item label="经办人证件号码" required>
            <van-field v-model="modelData.agent.agent_person_number"
                       v-limit="/[^0-9xX]/g"
                       :rules="[
                           {required: true},
                           {validator: idValidator, message: '请输入正确的经办人证件号码'}
                       ]"
                       inputmode="numeric"
                       placeholder="请输入经办人证件号码"
                       @input="getPersonInfo"
            />
        </my-item>
        <my-item label="经办人性别">
            <radio-button v-model="modelData.agent.agent_person_sex" :items="personSex"></radio-button>
        </my-item>
        <my-item label="经办人出生日期">
            <my-date v-model="modelData.agent.agent_person_birthday" placeholder="请输入经办人出生日期" />
        </my-item>
        <my-item label="经办人证件地址" required>
            <van-field v-model="modelData.agent.agent_person_addr" :rules="[{required: true}]" placeholder="请输入经办人证件地址" />
        </my-item>
        <my-item label="经办人证件有效期" required>
            <van-row align="center" gutter="8" type="flex">
                <van-col span="9">
                    <my-date v-model="modelData.agent.agent_begin_time" :rules="[{required: true}]" placeholder="请输入开始时间" />
                </van-col>
                <van-col span="2" style="text-align: center;">至</van-col>
                <van-col span="9">
                    <my-date v-model="modelData.agent.agent_end_time"
                             :disabled="modelData.agent.agent_end_time_forever == '1'"
                             :rules="[{required: modelData.agent.agent_end_time_forever == '2'}]"
                             placeholder="请输入结束时间"
                    />
                </van-col>
                <van-col span="4">
                    <van-switch v-model="modelData.agent.agent_end_time_forever"
                                active-value="1"
                                inactive-value="2"
                    />
                </van-col>
            </van-row>
        </my-item>
    </div>
</template>

<script>
    import {MyLink, MyItem, MyDate, MyUpload} from "@/components/MyPlugins";
    import RadioButton from "@/components/RadioButton";
    import {mapState} from "vuex";
    import {certificateOcr} from "@/api/openOrder";
    import metaData from "@/views/open-order/metaData";
    import {regex} from "@/utils/tools";
    import {IDCARD_REG, MOBILE_REG, TEL_REG} from "@/utils/keys";

    export default {
        inheritAttrs: false,
        name: "Step3",
        components: {MyLink, MyItem, MyDate, MyUpload, RadioButton},
        props: {
            modelData: Object
        },
        data() {
            return {
                idValidator: regex([IDCARD_REG]),
                mobileValidator: regex([MOBILE_REG, TEL_REG]),
            }
        },
        computed: {
            ...mapState('number', ['personType', 'personSex'])
        },
        methods: {
            getPersonInfo(value) {
                this.$idcard(value).then(res => {
                    let {sex, birthDay} = res;
                    this.modelData.agent.agent_person_sex = sex;
                    this.modelData.agent.agent_person_birthday = new Date(birthDay).getTime();
                });
            },
            onClear() {
                let {agent} = this.modelData;
                Object.keys(agent).forEach(key => {
                    agent[key] = metaData.agent[key];
                });
            },
            useLegal() {
                let {user_info, agent} = this.modelData;
                Object.keys(agent).forEach(key => {
                    let field = key.replace('agent_', 'legal_');
                    agent[key] = user_info[field];
                });
            },
            onOcr() {
                let {url} = this.modelData.agent.agent_person_img;
                if (!url) {
                    this.$toast.fail('请先上传证件正反面');
                    return;
                }
                this.$toast.loading({message: '正在识别中，请等待...', forbidClick: true, duration: 0});
                this.$file(url).then(res => {
                    let fd = new FormData();
                    fd.append('file', res);
                    certificateOcr(fd).then(res => {
                        this.$toast.clear();
                        let data = res.data;
                        this.modelData.agent.agent_person_type = '1';
                        this.modelData.agent.agent_person_name = data.personName;
                        this.modelData.agent.agent_person_number = data.personNumber;
                        this.modelData.agent.agent_person_sex = String(data.personSex);
                        this.modelData.agent.agent_person_birthday = new Date(data.personBirthday).getTime();
                        this.modelData.agent.agent_person_addr = data.personAddr;
                        this.modelData.agent.agent_begin_time = new Date(data.beginTime).getTime();
                        this.modelData.agent.agent_end_time = new Date(data.endTime).getTime();
                        this.modelData.agent.agent_end_time_forever = data.endTime ? '2' : '1';
                    }).catch(err => {
                        this.$toast.fail(err.msg);
                    });
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    .Step3 {

    }
</style>
