<template>
    <div class="Step4">
        <div class="Step4__header">
            <van-button native-type="button" size="small" style="flex: 1;" type="info" @click="onCreate">添加</van-button>
            <van-button native-type="button" plain size="small" type="info" @click="addAgentSeat">使用经办人信息</van-button>
            <van-button native-type="button" plain size="small" type="info" @click="addLegalSeat">使用法人信息</van-button>
        </div>
        <div v-if="modelData.seat.length > 0" class="Step4__seat">
            <div v-for="(item, index) in modelData.seat" :key="index" class="Step4__seatItem Item">
                <div class="Item__info">
                    <div class="Item__desc">
                        <div class="Item__descLabel">目的码类型</div>
                        <div class="Item__descValue">
                            <span>{{ item.seat_num_type | getMapText('number/seatNumType') }}</span>
                            <span v-if="item.valid == 1" style="margin-left: 8px; color: #4AD07E;">三要素验证通过</span>
                            <span v-else-if="item.valid == 2" style="margin-left: 8px; color: #EE0A24;">三要素验证未通过</span>
                        </div>
                    </div>
                    <div class="Item__desc">
                        <div class="Item__descLabel">电话号码</div>
                        <div class="Item__descValue">{{ item.seat_num }}</div>
                    </div>
                    <div class="Item__desc">
                        <div class="Item__descLabel">持有人姓名</div>
                        <div class="Item__descValue">{{ item.seat_name }}</div>
                    </div>
                    <div class="Item__desc">
                        <div class="Item__descLabel">持有人身份证号</div>
                        <div class="Item__descValue">{{ item.seat_card_id }}</div>
                    </div>
                </div>
                <i class="iconfont icon-jianshao" @click="onRemove(index)"></i>
            </div>
        </div>
        <div v-else class="Step4__seat Step4__seat--empty">
            请至少添加一个目的码
        </div>
        <create-seat ref="create"
                     :params="{number: modelData.number, section_code: modelData.section_code}"
                     :seats="modelData.seat"
                     @submit="onCreateSuccess"
        ></create-seat>
    </div>
</template>

<script>
    import CreateSeat from "./CreateSeat";
    import {numOpenValidatePhone} from "@/api/openOrder";

    export default {
        inheritAttrs: false,
        name: "Step4",
        components: {CreateSeat},
        props: {
            modelData: Object
        },
        data() {
            return {
                radio: 0
            }
        },
        methods: {
            onCreate() {
                this.$refs.create.visible = true;
            },
            onRemove(index) {
                this.modelData.seat.splice(index, 1);
            },
            onCreateSuccess(row) {
                this.modelData.seat.push(row);
            },
            // 根据法人信息添加目的码
            addLegalSeat() {
                let {legal_person_name: name, legal_person_mobile: mobile, legal_person_number: idcard} = this.modelData.user_info;
                this.onValidatePhone({name, mobile, idcard}).then(({valid, url}) => {
                    this.modelData.seat.push({
                        seat_num: mobile || '',
                        seat_name: name || '',
                        seat_card_id: idcard || '',
                        seat_num_type: "1",
                        seat_num_img: url || '',
                        valid
                    });
                });
            },
            // 根据经办人信息添加目的码
            addAgentSeat() {
                let {agent_person_name: name, agent_person_mobile: mobile, agent_person_number: idcard} = this.modelData.agent;
                this.onValidatePhone({name, mobile, idcard}).then(({valid, url}) => {
                    this.modelData.seat.push({
                        seat_num: mobile || '',
                        seat_name: name || '',
                        seat_card_id: idcard || '',
                        seat_num_type: "3",
                        seat_num_img: url || '',
                        valid
                    });
                });
            },
            // 三要素验证
            onValidatePhone(params) {
                let {number, section_code, seat} = this.modelData;
                let data = [{batch_name: number, ...params}];
                let isRepeat = seat.some(v => String(v.seat_num) === String(params.mobile));
                return new Promise((resolve, reject) => {
                    if (!isRepeat) {
                        let {idcard, mobile, name} = params;
                        if (idcard && mobile && name && ['9', '10'].includes(section_code)) {
                            this.$toast.loading({message: '三要素验证中，请稍后...', forbidClick: true, duration: 0});
                            numOpenValidatePhone(data).then(res => {
                                let [item] = res.data;
                                this.$toast.clear();
                                resolve({valid: item.code == 200 ? 1 : 2, url: item.url});
                            }).catch(err => {
                                this.$toast.fail(err.msg);
                                resolve();
                            });
                        } else {
                            resolve({valid: 0, url: ''});
                        }
                    } else {
                        this.$toast.fail('目的码不能重复');
                        reject();
                    }
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
    .Step4 {
        height: 100%;
        display: flex;
        flex-direction: column;
        &__header {
            display: flex;
            gap: 0 10px;
            margin-bottom: 16px;
        }
        &__seat {
            font-size: 14px;
            overflow: auto;
            &:not(.Step4__seat--empty) {
                flex: 1;
            }
            &Item {
                padding: 16px;
                background: #F7F8FA;
                border-radius: 4px;
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            .Item {
                display: flex;
                align-items: center;
                i {
                    font-size: 20px;
                    color: #F73434;
                    margin-left: auto;
                    cursor: pointer;
                }
                &__desc {
                    display: flex;
                    font-size: 12px;
                    gap: 0 8px;
                    line-height: 18px;
                    margin-bottom: 4px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &Label {
                        width: 84px;
                        color: #969799;
                    }
                    &Value {
                        color: #323233;
                    }
                }
            }
            &--empty {
                height: 326px;
                background: #F7F8FA;
                border-radius: 4px;
                color: #999999;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
</style>
