<template>
    <my-drawer v-model="visible"
               :fullscreen="false"
               :nav-left-arrow="false"
               position="bottom"
               round
               title="选择已有企业"
               @close="onClose"
    >
        <template #nav-right>
            <van-icon color="#323233" name="cross" size="18" @click="onClose" />
        </template>
        <div class="ChooseCompany">
            <div class="ChooseCompany__search">
                <van-search v-model="option.query.companyName"
                            placeholder="请输入企业名称或企业账号，支持模糊查询"
                            @search="onSearch"
                />
            </div>
            <div class="ChooseCompany__body">
                <my-scroll-list v-if="visible" ref="list" :option="option" :page-size="10">
                    <template #item="{item, $index}">
                        <div :class="{'ChooseCompany__item--active': active === $index}"
                             class="ChooseCompany__item CompanyInfo"
                             @click="onSelect($index)"
                        >
                            <div class="CompanyInfo__info">
                                <div class="CompanyInfo__name">{{ item.companyName }}</div>
                                <div class="CompanyInfo__desc">
                                    <div class="CompanyInfo__descLabel">企业账号</div>
                                    <div class="CompanyInfo__descValue">{{ item.userName }}</div>
                                </div>
                                <div class="CompanyInfo__desc">
                                    <div class="CompanyInfo__descLabel">统一社会信用代码</div>
                                    <div class="CompanyInfo__descValue">{{ item.companyNumber }}</div>
                                </div>
                                <div class="CompanyInfo__desc">
                                    <div class="CompanyInfo__descLabel">开通号码</div>
                                    <div class="CompanyInfo__descValue">{{ item.batchName }}</div>
                                </div>
                                <div class="CompanyInfo__desc">
                                    <div class="CompanyInfo__descLabel">创建时间</div>
                                    <div class="CompanyInfo__descValue">{{ item.createTime }}</div>
                                </div>
                            </div>
                            <div class="CompanyInfo__state">
                                <span style="font-size: 12px;">
                                    {{ item.auditStatus | getMapText('openOrder/auditStatus') }}
                                </span>
                                <van-radio-group v-model="active">
                                    <van-radio :name="$index"></van-radio>
                                </van-radio-group>
                            </div>
                        </div>
                    </template>
                </my-scroll-list>
            </div>
        </div>
        <template #footer>
            <van-button block native-type="button" type="default" @click="onClose">取消</van-button>
            <van-button block native-type="button" type="info" @click="onSubmit">确定使用</van-button>
        </template>
    </my-drawer>
</template>

<script>
    import {MyDrawer, MyScrollList} from "@/components/MyPlugins";
    import {chooseUserList, chooseCompany} from "@/api/openOrder";
    import {mapState} from "vuex";

    export default {
        name: "ChooseCompany",
        components: {MyDrawer, MyScrollList},
        data() {
            return {
                visible: false,
                active: null,
                option: {
                    api: chooseUserList,
                    query: {
                        companyName: ''
                    }
                }
            }
        },
        computed: {
            ...mapState('openOrder', ['auditStatus'])
        },
        methods: {
            onSubmit() {
                const toTimestamp = value => {
                    if (!value || ["0000-00-00", "0000-00-00 00:00:00"].includes(value)) {
                        return '';
                    } else {
                        return new Date(value).getTime();
                    }
                }
                if (this.active !== null) {
                    let item = this.$refs.list.getItem(this.active);
                    let {mmfCode, userCode, batchCode} = item;
                    let params = {userCode};
                    if (mmfCode) params.batchCode = batchCode;
                    chooseCompany(params).then(res => {
                        let {certificate: companyImg, corporate: legalPersonImg, ...userInfo} = res.data;
                        userInfo.companyStartTime && (userInfo.companyStartTime = toTimestamp(userInfo.companyStartTime));
                        userInfo.companyEndTime && (userInfo.companyEndTime = toTimestamp(userInfo.companyEndTime));
                        userInfo.companyEndTimeForever = userInfo.companyEndTime ? '2' : '1';
                        userInfo.legalBeginTime && (userInfo.legalBeginTime = toTimestamp(userInfo.legalBeginTime));
                        userInfo.legalEndTime && (userInfo.legalEndTime = toTimestamp(userInfo.legalEndTime));
                        userInfo.legalEndTimeForever = userInfo.legalEndTime ? '2' : '1';
                        userInfo.legalPersonBirthday && (userInfo.legalPersonBirthday = toTimestamp(userInfo.legalPersonBirthday));
                        userInfo.legalPersonType = String(userInfo.legalPersonType || '');
                        userInfo.legalPersonSex = String(userInfo.legalPersonSex || '');

                        this.$emit('change', {...item, ...userInfo, companyImg, legalPersonImg});
                        this.onClose();
                    });
                } else {
                    this.$toast.fail('请选择企业信息');
                }
            },
            onSearch() {
                this.$refs.list.onSearch();
            },
            onSelect(index) {
                this.active = index;
            },
            onClose() {
                this.active = null;
                this.option.query.companyName = '';
                this.visible = false;
            },
        }
    }
</script>

<style lang="scss" scoped>
    .ChooseCompany {
        height: 450px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        &__search {
            padding: 10px 4px;
        }
        &__body {
            flex: 1;
            padding: 0 16px;
            overflow: auto;
        }
        &__item {
            border-radius: 4px;
            margin-bottom: 8px;
            padding: 16px;
            border: 1px solid #DDE5F4;
            &:last-child {
                margin-bottom: 0;
            }
            &--active {
                border: 1px solid #1677FF;
            }
        }
        .CompanyInfo {
            display: flex;
            &__name {
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 8px;
            }
            &__desc {
                display: flex;
                font-size: 12px;
                gap: 0 8px;
                line-height: 18px;
                margin-bottom: 4px;
                &:last-child {
                    margin-bottom: 0;
                }
                &Label {
                    width: 96px;
                    color: #969799;
                }
                &Value {
                    color: #323233;
                }
            }
            &__info {
                flex: 1;
                margin-right: 4px;
            }
            &__state {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-end;
            }
        }
    }
</style>
