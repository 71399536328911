var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('my-drawer',{attrs:{"fullscreen":false,"nav-left-arrow":false,"position":"bottom","round":"","title":"新建企业"},on:{"close":_vm.onClose},scopedSlots:_vm._u([{key:"nav",fn:function(){return [_c('div',{staticClass:"Nav"},[_c('div',{staticClass:"Nav__title"},[_vm._v("新建企业")]),_c('div',{staticClass:"Nav__description"},[_vm._v(" 信息确定后将完成企业账号的建立，请注意记录企业账号与密码信息。 ")]),_c('van-icon',{staticClass:"Nav__close",attrs:{"color":"#323233","name":"cross","size":"18"},on:{"click":_vm.onClose}})],1)]},proxy:true},{key:"footer",fn:function(){return [_c('van-button',{attrs:{"block":"","native-type":"button","type":"default"},on:{"click":_vm.onClose}},[_vm._v("取消")]),_c('van-button',{attrs:{"block":"","native-type":"button","type":"info"},on:{"click":function($event){return _vm.$refs.form.submit();}}},[_vm._v("确定")])]},proxy:true}]),model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{staticClass:"CreateCompany"},[_c('van-form',{ref:"form",on:{"failed":_vm.onFailed,"submit":_vm.onSubmit}},[_c('my-item',{attrs:{"label":"企业账号","required":""}},[_c('van-field',{directives:[{name:"limit",rawName:"v-limit"}],attrs:{"rules":[
                               {required: true},
                               {validator: _vm.userNameValidator}
                           ],"placeholder":"6-20位数字和大小写字母组合"},model:{value:(_vm.modelData.userName),callback:function ($$v) {_vm.$set(_vm.modelData, "userName", $$v)},expression:"modelData.userName"}})],1),_c('my-item',{attrs:{"label":"登录密码","required":""}},[_c('div',{staticClass:"field-group"},[_c('van-field',{directives:[{name:"limit",rawName:"v-limit"}],attrs:{"rules":[
                                   {required: true},
                                   {validator: _vm.pwdValidator}
                               ],"placeholder":"6-15位必须包含数字和大小写字母"},model:{value:(_vm.modelData.companyPwd),callback:function ($$v) {_vm.$set(_vm.modelData, "companyPwd", $$v)},expression:"modelData.companyPwd"}}),_c('van-button',{attrs:{"native-type":"button","size":"normal","type":"info"},on:{"click":_vm.getPw}},[_vm._v("随机生成")])],1)]),_c('my-item',{attrs:{"label":"注册手机","required":""}},[_c('van-field',{attrs:{"rules":[
                               {required: true},
                               {validator: _vm.mobileValidator, message: '请输入正确的手机号'}
                           ],"placeholder":"请输入手机号","type":"number"},model:{value:(_vm.modelData.userTel),callback:function ($$v) {_vm.$set(_vm.modelData, "userTel", $$v)},expression:"modelData.userTel"}})],1),_c('my-item',{attrs:{"label":"注册邮箱"}},[_c('van-field',{directives:[{name:"limit",rawName:"v-limit:email",arg:"email"}],attrs:{"rules":[{validator: _vm.emailValidator, message: '请输入正确的邮箱'}],"placeholder":"请输入邮箱"},model:{value:(_vm.modelData.userEmail),callback:function ($$v) {_vm.$set(_vm.modelData, "userEmail", $$v)},expression:"modelData.userEmail"}})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }