<template>
    <div class="CompanyInfo">
        <div class="field-group">
            <van-field v-limit :rules="rules" :value="value" v-bind="$attrs" @input="onInput" />
            <van-button native-type="button" size="normal" type="info" @click="onSearch">查询工商信息</van-button>
        </div>
        <my-drawer v-model="visible"
                   :fullscreen="false"
                   :nav-left-arrow="false"
                   position="bottom"
                   round
                   title="选择企业"
                   @closed="onClose"
        >
            <div class="CompanyInfo__result">
                <my-scroll-list v-if="visible" ref="list" :option="option">
                    <template #item="{item}">
                        <div class="CompanyInfo__item Item" @click="onSelect(item)">
                            <div class="Item__name">{{ item.name }}</div>
                            <div class="Item__desc">
                                <div class="Item__descLabel">经营状态</div>
                                <div class="Item__descValue">{{ item.status }}</div>
                            </div>
                            <div class="Item__desc">
                                <div class="Item__descLabel">统一社会信用代码</div>
                                <div class="Item__descValue">{{ item.creditCode }}</div>
                            </div>
                        </div>
                    </template>
                </my-scroll-list>
            </div>
            <template #footer>
                <van-button block native-type="button" type="default" @click="onClose">取消</van-button>
            </template>
        </my-drawer>
    </div>
</template>

<script>
    import {MyDrawer, MyScrollList} from "@/components/MyPlugins";
    import {getApplyInfoFuzzy, getApplyInfo} from "@/api/openOrder";

    export default {
        name: "CompanyInfo",
        components: {MyDrawer, MyScrollList},
        props: {
            value: String
        },
        model: {
            prop: 'value',
            event: 'input'
        },
        data() {
            return {
                visible: false,
                option: {
                    api: getApplyInfoFuzzy,
                    query: {
                        searchKey: ''
                    }
                },
                item: null
            }
        },
        computed: {
            rules() {
                let rules = this.$attrs.rules;

                return [
                    ...rules,
                    {validator: this.checkKeyword}
                ];
            }
        },
        methods: {
            // 企业名称关键字限制
            checkKeyword(value) {
                let keys = ['有限公司', '股份有限公司', '集团有限公司', '有限', '公司', '股份', '集团'];
                return new Promise(resolve => {
                    if (value.length < 2) {
                        this.$toast.fail('输入了少于2个字符');
                        resolve(false);
                    } else {
                        if (keys.indexOf(value) > -1) {
                            this.$toast.fail('请输入正确的企业名称');
                            resolve(false);
                        } else {
                            resolve(true);
                        }
                    }
                });
            },
            onClose() {
                this.visible = false;
                this.option.query.searchKey = '';
            },
            onSelect(item) {
                getApplyInfo({searchKey: item.name, update: 2}).then(res => {
                    let {updateDate, companyTermStartTime, companyTermEndTime, ...data} = res.data;
                    this.item = {...item, updateDate};
                    this.$emit('input', item.name);
                    this.$emit('select', {
                        ...data,
                        companyStartTime: companyTermStartTime ? new Date(companyTermStartTime).getTime() : '',
                        companyEndTime: companyTermEndTime ? new Date(companyTermEndTime).getTime() : ''
                    });
                    this.onClose();
                });
            },
            onInput(value) {
                this.$emit('input', value);
            },
            onSearch() {
                if (!this.value) {
                    this.option.query.searchKey = '';
                    return;
                }
                this.checkKeyword(this.value).then(res => {
                    if (res) {
                        this.option.query.searchKey = this.value;
                        this.visible = true;
                    }
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    .CompanyInfo {
        &__result {
            height: 300px;
        }
        &__item {
            border-radius: 4px;
            margin: 8px 16px;
            padding: 16px;
            //border: 1px solid #DDE5F4;
            &:last-child {
                margin-bottom: 0;
            }
            &--active {
                border: 1px solid #1677FF;
            }
        }
        .Item {
            background: #F7F8FA;
            border-radius: 4px;
            &__name {
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 8px;
            }
            &__desc {
                display: flex;
                font-size: 12px;
                gap: 0 8px;
                line-height: 18px;
                margin-bottom: 4px;
                &:last-child {
                    margin-bottom: 0;
                }
                &Label {
                    width: 96px;
                    color: #969799;
                }
                &Value {
                    color: #323233;
                }
            }
        }
    }
</style>
