<template>
    <div class="OpenOrder">
        <div class="OpenOrder__progress">
            <div v-if="showNavbar">
                <div style="font-weight: 600;">进度总览</div>
                <div class="OpenOrder__tips">请按照以下步骤，依次填写信息。支持点击快速切换步骤。</div>
            </div>
            <my-steps :current.sync="current" :items="items"></my-steps>
        </div>
        <div class="OpenOrder__body">
            <van-form ref="form" @submit="onSubmit">
                <component :is="`step-${current}`" ref="step" :model-data="modelData"></component>
            </van-form>
        </div>
        <div class="OpenOrder__footer">
            <div class="Cost">
                <div>支付成本</div>
                <div style="color: #EE0A24; margin-top: 4px;">￥{{ Number(callinCost || 0).toFixed(2) }}</div>
            </div>
            <div class="Info">
                <div class="Info__text">
                    合约期{{ modelData.period || 0 }}个月 / 套餐周期{{ modelData.treaty_month || 0 }}个月
                </div>
                <div class="Info__text">免费增值功能将全部开通</div>
                <div class="Info__btn">
                    <van-button native-type="button" block type="info" @click="onSaveDraft">仅保存</van-button>
                    <van-button native-type="button" block type="info" @click="onFormValidate">提交</van-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {MySteps} from "@/components/MyPlugins";
    import Step0 from "./steps/Step0";
    import Step1 from "./steps/Step1";
    import Step2 from "./steps/Step2";
    import Step3 from "./steps/Step3";
    import Step4 from "./steps/Step4";
    import Step5 from "./steps/Step5";
    import {draftDetail, draftSave, getAgentCost, getBasicProductList, submitOrder} from "@/api/openOrder";
    import metaData from "./metaData";
    import {mapActions, mapState} from "vuex";
    import {isEmpty, timeFormat} from "@/utils/tools";
    import {get} from "lodash";

    export default {
        name: "OpenOrder",
        components: {MySteps, Step0, Step1, Step2, Step3, Step4, Step5},
        data() {
            return {
                items: [
                    {text: '企业账号', dot: false, props: ['user_code']},
                    {
                        text: '工商信息',
                        dot: false,
                        props: () => {
                            let {company_end_time_forever} = this.modelData.user_info;
                            let props = [
                                'user_info.company_name',
                                'user_info.company_number',
                                'user_info.company_reg_money',
                                'user_info.company_location',
                                'user_info.company_address',
                                'user_info.company_mobile',
                                'user_info.company_email',
                                'user_info.company_industry',
                                'user_info.company_scope',
                                'user_info.company_img',
                                'user_info.company_start_time',
                                'user_info.company_project',
                            ];
                            if (company_end_time_forever == '2') props.push('user_info.company_end_time');

                            return props;
                        }
                    },
                    {
                        text: '法人',
                        dot: false,
                        props: () => {
                            let {legal_end_time_forever} = this.modelData.user_info;
                            let props = [
                                'user_info.legal_person_img',
                                'user_info.legal_person_name',
                                'user_info.legal_person_mobile',
                                'user_info.legal_person_number',
                                'user_info.legal_person_addr',
                                'user_info.legal_begin_time',
                            ];
                            if (legal_end_time_forever == '2') props.push('user_info.legal_end_time');

                            return props;
                        }
                    },
                    {
                        text: '经办人',
                        dot: false,
                        props: () => {
                            let {agent_end_time_forever} = this.modelData.agent;
                            let props = [
                                'agent.agent_person_img',
                                'agent.agent_person_name',
                                'agent.agent_person_mobile',
                                'agent.agent_person_number',
                                'agent.agent_person_addr',
                                'agent.agent_begin_time',
                            ];
                            if (agent_end_time_forever == '2') props.push('agent.agent_end_time');

                            return props;
                        }
                    },
                    {text: '目的码', dot: false, props: ['seat']},
                    {
                        text: '套餐',
                        dot: false,
                        props: ['deduct_type', 'callin_meal_code', 'period', 'callin_min_fee', 'callin_price']
                    },
                ],
                current: 0,
                showNavbar: true,
                modelData: JSON.parse(JSON.stringify(metaData)),
                loading: false,
                agentCost: null,
                trashCode: ''
            }
        },
        computed: {
            ...mapState('openOrder', ['meals']),
            currMeal() {
                let {callin_meal_code} = this.modelData;
                if (callin_meal_code && this.meals.length > 0) {
                    return this.meals.find(v => v.meal_code == callin_meal_code);
                }

                return null;
            },
            // 套餐成本
            callinCost() {
                if (this.agentCost && this.currMeal) {
                    let {deduct_type, meal_type, period, callin_price, callin_min_fee} = this.modelData;

                    if (deduct_type == '2') { // 分钟数
                        if (meal_type == '1') { // 标准套餐
                            return (callin_min_fee * period * this.agentCost.minutePrice) / callin_price;
                        } else if (meal_type == '2') { // 活动套餐
                            return (callin_min_fee * period * this.currMeal.minute_price) / callin_price;
                        }
                    } else if (deduct_type == '4') { // 折扣
                        if (meal_type == '1') { // 标准套餐
                            return callin_min_fee * period * this.agentCost.discountRatio;
                        } else if (meal_type == '2') { // 活动套餐
                            return callin_min_fee * period * this.currMeal.cost_discount;
                        }
                    } else if (['1', '3'].includes(deduct_type)) {
                        return this.currMeal.cost_fee * period;
                    }
                }

                return 0;
            },
        },
        watch: {
            current: {
                handler() {
                    document.querySelector('.OpenOrder__body').scrollTo({top: 0, behavior: 'smooth'});
                }
            }
        },
        mounted() {
            this.getDraftInfo().then(() => {
                let {number, meal_type: mealType, deduct_type: deductType} = this.modelData;
                let {meta} = this.$route;
                document.title = number + meta.title;
                this.getMeals({batchName: number, mealType, deductType});
                this.getCost();
            }).catch(() => {

            });
            const el = document.querySelector('.OpenOrder__body');
            el.addEventListener('scroll', e => {
                const {scrollTop, scrollHeight, clientHeight} = e.target;
                if (scrollHeight - clientHeight > 50) {
                    this.showNavbar = scrollTop === 0;
                } else {
                    this.showNavbar = true;
                }
            }, false);
        },
        methods: {
            ...mapActions('openOrder', ['getMeals']),
            getProducts() {
                getBasicProductList().then(res => {
                    let list = res.data;
                    this.modelData.product_info = list.filter(e => e.isChoice == 1 && Number(e.cost) == 0).map(v => {
                        return {
                            product_code: v.productCode,
                            product_amount: 1,
                            product_month: ''
                        }
                    });
                });
            },
            getDraftInfo() {
                let {number, trashCode, sectionCode} = this.$route.query;
                return new Promise((resolve, reject) => {
                    if (trashCode) {
                        this.trashCode = trashCode;
                        draftDetail({trashCode}).then(res => {
                            let {trashData} = res.data;
                            let data = JSON.parse(trashData);
                            let result = this.fmtDate(data);
                            Object.keys(result).forEach(key => {
                                if (typeof this.modelData[key] !== 'undefined') {
                                    this.modelData[key] = result[key];
                                }
                            });
                            resolve();
                        }).catch(reject);
                    } else if (number) {
                        this.modelData.number = number;
                        this.modelData.section_code = sectionCode;
                        this.getProducts();
                        resolve();
                    } else {
                        reject();
                    }
                });
            },
            getCost() {
                getAgentCost().then(res => {
                    this.agentCost = res.data
                });
            },
            onSaveDraft() {
                let modelData = JSON.parse(JSON.stringify(this.modelData));
                let {number: batchName, product_info, treaty_month} = modelData;
                if (product_info && product_info.length > 0) {
                    product_info.map(v => v.product_month = treaty_month);
                }
                let data = new FormData();
                data.append('batchName', batchName);
                data.append('trashCode', this.trashCode);
                data.append('trashData', JSON.stringify(modelData));
                this.$toast.loading({message: '正在保存中，请稍后...', forbidClick: true, duration: 0});
                draftSave(data).then(res => {
                    this.trashCode = res.data;
                    let time = timeFormat(new Date().getTime());
                    this.$toast.success(`${time} 草稿保存成功`);
                });
            },
            // 处理数据中的时间戳
            fmtDate(data, fmt = false) {
              window.console.log(1);
                let {agent, user_info} = data;
              window.console.log(agent);

                const t = value => {
                  window.console.log(9);

                    let v = Number(value);
                    if (isNaN(v)) {
                      window.console.log(4);

                        return new Date(value).getTime();
                    } else if (fmt) {
                      window.console.log(5);

                        return timeFormat(value, 'yy-MM-dd');
                    } else {

                        return value;
                    }
                }
              window.console.log(3);

                // 经办人
                agent.agent_begin_time && (agent.agent_begin_time = t(agent.agent_begin_time));

                agent.agent_end_time && (agent.agent_end_time = t(agent.agent_end_time));
                agent.agent_person_birthday && (agent.agent_person_birthday = t(agent.agent_person_birthday));

                // 法人
                user_info.company_start_time && (user_info.company_start_time = t(user_info.company_start_time));
                user_info.company_end_time && (user_info.company_end_time = t(user_info.company_end_time));
                user_info.legal_begin_time && (user_info.legal_begin_time = t(user_info.legal_begin_time));
                user_info.legal_end_time && (user_info.legal_end_time = t(user_info.legal_end_time));
                user_info.legal_person_birthday && (user_info.legal_person_birthday = t(user_info.legal_person_birthday));

                return data;
            },
            onSubmit() {
                if (this.loading) return;
                this.onValidate(valid => {
                    if (valid) {
                        this.loading = true;
                        this.$toast.loading({message: '提交中，请稍后...', forbidClick: true, duration: 0});
                        let modelData = JSON.parse(JSON.stringify(this.modelData));
                        // 处理时间戳
                        let {user_info, number, product_info, treaty_month} = this.fmtDate(modelData, true);
                        if (product_info && product_info.length > 0) {
                            product_info.map(v => v.product_month = treaty_month);
                        }
                        // 格式化地区字段
                        if (user_info.company_location && user_info.company_location.length > 0) {
                            let [company_province, company_city] = user_info.company_location;
                            user_info = {...user_info, company_province, company_city};
                        }
                        this.$delete(user_info, 'company_location');
                        // 格式化行业类型字段
                        if (user_info.company_industry && user_info.company_industry.length > 0) {
                            let [company_class_code, company_mince_code] = user_info.company_industry;
                            user_info = {...user_info, company_class_code, company_mince_code};
                        }
                        this.$delete(user_info, 'company_industry');
                        submitOrder({...modelData, user_info}).then(res => {
                            this.$toast.success({
                                message: `号码 ${this.modelData.number} 已提单完成`,
                                onClose: () => {
                                    let {batch_code: batchCode, mmf_code: mmfCode} = res.data;
                                    this.$router.push({path: '/materials/detail', query: {batchCode, batchName: number, mmfCode}});
                                }
                            });
                            this.loading = false;
                        }).catch(() => {
                            this.loading = false;
                        });
                    } else {
                        this.$toast.fail('请检查表单是否填写正确');
                    }
                });
            },
            // 获取徽标状态
            onValidate(cb) {
                this.items.forEach(item => {
                     item.dot = this.getPropsValidate(item.props);
                });
                cb && cb(this.items.every(v => !v.dot));
            },
            getPropsValidate(props) {
                let list = typeof props === 'function' ? props() : props;
                return list.some(prop => {
                    return isEmpty(get(this.modelData, prop));
                });
            },
            // 步骤条切换事件
            onBeforeLeave() {
                let item = this.items[this.current];
                item.dot = this.getPropsValidate(item.props);
                if (item.dot) this.$toast.fail('请检查表单是否填写正确');

                return true;
            },
            onFormValidate() {
                this.$refs.form.submit();
                this.onValidate(valid => {
                    !valid && this.$toast.fail('请检查表单是否填写正确');
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    .OpenOrder {
        display: flex;
        flex-direction: column;
        height: 100vh;
        &__progress {
            padding: 16px;
            font-size: 14px;
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);
            border-radius: 0 0 10px 10px;
        }
        &__tips {
            font-size: 12px;
            color: #969799;
            margin: 4px 0 8px 0;
        }
        &__body {
            flex: 1;
            overflow: auto;
            padding: 20px 16px;
        }
        &__footer {
            box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, 0.05);
            padding: 10px 16px;
            display: flex;
            gap: 0 16px;
            .Cost {
                width: 164px;
                padding: 18px 12px;
                font-size: 14px;
                font-weight: 600;
                background: #F7F8FA;
                line-height: 20px;
                border-radius: 4px;
            }
            .Info {
                flex: 1;
                &__text {
                    font-size: 12px;
                    color: #969799;
                    line-height: 16px;
                }
                &__btn {
                    display: flex;
                    gap: 0 10px;
                    margin-top: 8px;
                    .van-button {
                        height: 40px;
                    }
                }
            }
        }
    }
</style>
