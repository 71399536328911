export default {
    batch_code: "",
    meal_type: "1",
    mmf_code: "",
    number: '',
    user_code: "",
    period: "",
    treaty_month: "",
    callin_meal_code: "",
    organ_code: "",
    deduct_type: "",
    callin_min_fee: "",
    callin_price: "",
    callin_minutes: "",
    staff_code: "",
    order_remark: "",
    order_callin_fee: "",
    section_code: "",
    user_info: {
        audit_status: "",
        user_name: "",
        user_email: "",
        user_tel: "",
        company_name: "",
        company_reg_money: "",
        company_number: "",
        company_location: [],
        company_address: "",
        company_mobile: "",
        company_email: "",
        company_industry: [],
        company_scope: "1",
        company_start_time: "",
        company_end_time: "",
        company_end_time_forever: "2",
        company_img: {name: "", src: "", url: ""},
        company_project: "",
        legal_person_name: "",
        legal_person_mobile: "",
        legal_person_type: "1",
        legal_person_number: "",
        legal_person_sex: "1",
        legal_person_birthday: "",
        legal_person_addr: "",
        legal_begin_time: "",
        legal_end_time: "",
        legal_end_time_forever: "2",
        legal_person_img: {name: "", src: "", url: ""},
    },
    agent: {
        agent_person_name: "",
        agent_person_mobile: "",
        agent_person_type: "1",
        agent_person_number: "",
        agent_person_sex: "1",
        agent_person_birthday: "",
        agent_person_addr: "",
        agent_begin_time: "",
        agent_end_time: "",
        agent_end_time_forever: "2",
        agent_person_img: {name: "", src: "", url: ""},
    },
    seat: [],
    product_info: [],
}
