<template>
    <div class="Step0">
        <div :class="['Step0__info', {'Step0__info--empty': !modelData.user_info.user_name}]">
            <div v-if="modelData.user_info.user_name" class="Info">
                <div class="Info__title"><span style="color: #F56C6C;">*</span> 账号信息</div>
                <div class="Info__desc">
                    <div class="Info__descLabel">注册账号</div>
                    <div class="Info__descValue">{{ modelData.user_info.user_name }}</div>
                </div>
                <div class="Info__desc">
                    <div class="Info__descLabel">注册名称</div>
                    <div class="Info__descValue">{{ modelData.user_info.company_name }}</div>
                </div>
                <div v-if="modelData.user_info.user_email" class="Info__desc">
                    <div class="Info__descLabel">注册邮箱</div>
                    <div class="Info__descValue">{{ modelData.user_info.user_email }}</div>
                </div>
                <div v-if="modelData.user_info.user_tel" class="Info__desc">
                    <div class="Info__descLabel">注册手机</div>
                    <div class="Info__descValue">{{ modelData.user_info.user_tel }}</div>
                </div>
            </div>
            <span v-else class="Step0__infoText">请选择或新建一个企业账号</span>
        </div>
        <div class="Step0__do">
            <van-button block native-type="button" plain type="info" @click="onChooseCompany">选择已有企业</van-button>
            <van-button block native-type="button" plain type="info" @click="onCreateCompany">新建企业</van-button>
        </div>
        <create-company ref="create" @submit="onChoose"></create-company>
        <choose-company ref="choose" @change="onChoose"></choose-company>
    </div>
</template>

<script>
    import CreateCompany from "./CreateCompany";
    import ChooseCompany from "./ChooseCompany";
    import {snakeCase} from "lodash";
    import metaData from "../../metaData";

    export default {
        inheritAttrs: false,
        name: "Step0",
        components: {CreateCompany, ChooseCompany},
        props: {
            modelData: Object
        },
        methods: {
            onCreateCompany() {
                this.$refs.create.visible = true;
            },
            onChoose(row) {
                let {userCode, ...userInfo} = row;
                this.modelData.user_code = userCode;
                Object.keys(userInfo).forEach(key => {
                    // 法人
                    let field = snakeCase(key);
                    if (typeof this.modelData.user_info[field] !== 'undefined') {
                        this.modelData.user_info[field] = userInfo[key] || metaData.user_info[field];
                    }
                    // 经办人
                    if (field.indexOf('legal_') === 0) {
                        let keys = field.replace('legal_', 'agent_');
                        if (typeof this.modelData.agent[keys] !== 'undefined') {
                            this.modelData.agent[keys] = userInfo[key] || metaData.agent[keys];
                        }
                    }
                });
            },
            onChooseCompany() {
                this.$refs.choose.visible = true;
            },
        }
    }
</script>

<style lang="scss" scoped>
    .Step0 {
        height: 100%;
        &__info {
            border-radius: 4px;
            background: #F7F8FA;
            margin-bottom: 16px;
            &--empty {
                height: 160px;
                display: flex;
                align-items: center;
                justify-content: center;
                .Step0__infoText {
                    line-height: 20px;
                    font-size: 14px;
                    color: #999999;
                    display: inline-block;
                }
            }
            .Info {
                padding: 16px;
                &__title {
                    font-size: 14px;
                    font-weight: 600;
                    margin-bottom: 8px;
                }
                &__desc {
                    display: flex;
                    font-size: 12px;
                    gap: 0 8px;
                    line-height: 18px;
                    margin-bottom: 4px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &Label {
                        width: 56px;
                        color: #969799;
                    }
                    &Value {
                        color: #4F4F4F;
                    }
                }
            }
        }
        &__do {
            display: flex;
            gap: 0 10px;
        }
    }
</style>
