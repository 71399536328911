<template>
    <my-drawer v-model="visible"
               :fullscreen="false"
               :nav-left-arrow="false"
               position="bottom"
               round
               title="新建企业"
               @close="onClose"
    >
        <template #nav>
            <div class="Nav">
                <div class="Nav__title">新建企业</div>
                <div class="Nav__description">
                    信息确定后将完成企业账号的建立，请注意记录企业账号与密码信息。
                </div>
                <van-icon class="Nav__close" color="#323233" name="cross" size="18" @click="onClose" />
            </div>
        </template>
        <div class="CreateCompany">
            <van-form ref="form" @failed="onFailed" @submit="onSubmit">
                <my-item label="企业账号" required>
                    <van-field v-model="modelData.userName"
                               v-limit
                               :rules="[
                                   {required: true},
                                   {validator: userNameValidator}
                               ]"
                               placeholder="6-20位数字和大小写字母组合"
                    />
                </my-item>
                <my-item label="登录密码" required>
                    <div class="field-group">
                        <van-field v-model="modelData.companyPwd"
                                   v-limit
                                   :rules="[
                                       {required: true},
                                       {validator: pwdValidator}
                                   ]"
                                   placeholder="6-15位必须包含数字和大小写字母"
                        />
                        <van-button native-type="button" size="normal" type="info" @click="getPw">随机生成</van-button>
                    </div>
                </my-item>
                <my-item label="注册手机" required>
                    <van-field v-model="modelData.userTel"
                               :rules="[
                                   {required: true},
                                   {validator: mobileValidator, message: '请输入正确的手机号'}
                               ]"
                               placeholder="请输入手机号"
                               type="number"
                    />
                </my-item>
                <my-item label="注册邮箱">
                    <van-field v-model="modelData.userEmail"
                               v-limit:email
                               :rules="[{validator: emailValidator, message: '请输入正确的邮箱'}]"
                               placeholder="请输入邮箱"
                    />
                </my-item>
            </van-form>
        </div>
        <template #footer>
            <van-button block native-type="button" type="default" @click="onClose">取消</van-button>
            <van-button block native-type="button" type="info" @click="$refs.form.submit();">确定</van-button>
        </template>
    </my-drawer>
</template>

<script>
    import {MyDrawer, MyItem} from "@/components/MyPlugins";
    import {getRandomPassword, regex} from "@/utils/tools";
    import {addCompany} from "@/api/openOrder";
    import {MOBILE_REG, EMAIL_REG} from "@/utils/keys";

    export default {
        name: "CreateCompany",
        components: {MyDrawer, MyItem},
        data() {
            return {
                // 手机号验证
                mobileValidator: regex([MOBILE_REG]),
                // 邮箱验证
                emailValidator: regex([EMAIL_REG]),
                // 企业账号验证
                userNameValidator: regex([/^[0-9a-zA-Z]{6,20}$/]),
                // 登录密码验证
                pwdValidator: regex([/^(?=.*?[a-z])(?=.*?[0-9]).{6,15}$/]),
                loading: false,
                visible: false,
                modelData: {
                    companyName: "",
                    companyPwd: "",
                    userEmail: "",
                    userName: "",
                    userTel: "",
                    userType: 1
                }
            }
        },
        methods: {
            onFailed() {
                this.$toast.fail('请检查表单是否填写正确');
            },
            onSubmit() {
                if (this.loading) return;
                this.loading = true;
                this.$toast.loading({message: '正在保存中，请稍后..', forbidClick: true, duration: 0});
                addCompany(this.modelData).then(res => {
                    let userCode = res.data;
                    this.loading = false;
                    this.$toast.success('企业建立完成');
                    this.$emit('submit', {...this.modelData, userCode});
                    this.onClose();
                }).catch(() => {
                    this.loading = false;
                });
            },
            doReset() {
                this.modelData = {
                    companyName: "",
                    companyPwd: "",
                    userEmail: "",
                    userName: "",
                    userTel: "",
                    userType: 1
                };
            },
            onClose() {
                this.doReset();
                this.visible = false;
            },
            // 获取随机密码
            getPw() {
                let pw = getRandomPassword();
                this.modelData.companyPwd = pw;
            },
        }
    }
</script>

<style lang="scss" scoped>
    .Nav {
        padding: 20px 16px 0 16px;
        text-align: center;
        position: relative;
        &__title {
            line-height: 24px;
            font-size: 16px;
            color: #101010;
            margin-bottom: 4px;
        }
        &__description {
            color: #969799;
            font-size: 12px;
            text-align: left;
            line-height: 18px;
        }
        &__close {
            position: absolute;
            right: 16px;
            top: 20px;
        }
    }
    .CreateCompany {
        padding: 20px 16px;
    }
</style>
