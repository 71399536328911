<template>
    <div class="Step5">
        <my-item label="套餐类型" required>
            <radio-button v-model="modelData.meal_type" :items="mealType" @change="getMealList"></radio-button>
        </my-item>
        <my-item label="结算方式" required>
            <my-select-picker v-if="deducts.length > 0"
                              v-model="modelData.deduct_type"
                              :options="deducts"
                              :rules="[{required: true}]"
                              placeholder="请选择结算方式"
                              @change="getMealList"
            />
        </my-item>
        <my-item label="呼入套餐" required>
            <my-select-picker v-model="modelData.callin_meal_code"
                              :options="mealOptions"
                              :rules="[{required: true}]"
                              placeholder="请选择呼入套餐"
                              @change="onMealSelect"
            />
        </my-item>
        <my-item label="合约期" required>
            <div style="display: flex; align-items: center;">
                <van-field v-model="modelData.period"
                           v-limit="/[^0-9]/g"
                           :rules="[{required: true}]"
                           maxlength="2"
                           placeholder="请输入合约期"
                           style="width: 150px;"
                           type="number"
                           @input="onInputTreaty"
                ></van-field>
                <span v-if="currMeal && modelData.period" style="font-size: 14px; margin-left: 10px;">
                    周期 x {{ currMeal.meal_period }}个月 = {{ modelData.period * currMeal.meal_period }}个月
                </span>
            </div>
        </my-item>
        <my-item label="套餐售价" required>
            <span slot="label-right">
                售价区间范围【{{ currMeal && `${currMeal.meal_start_fee}-${currMeal.meal_end_fee}` }}】元
            </span>
            <van-field v-model="modelData.callin_min_fee"
                       :rules="[{required: true}, {validator: checkMinutes}]"
                       placeholder="请输入套餐售价"
                       type="number"
                       @blur="onBlur"
                       @input="getMinutes"
            ></van-field>
        </my-item>
        <my-item label="套餐资费" required>
            <span slot="label-right">
                资费区间范围【{{ currMeal && `${Number(currMeal.meal_start_price).toFixed(2)}-${Number(currMeal.meal_end_price).toFixed(2)}` }}】元/分钟
            </span>
            <van-field v-model="modelData.callin_price"
                       :rules="[{required: true}, {validator: checkMinutes}]"
                       placeholder="请输入套餐资费"
                       type="number"
                       @blur="onBlur"
                       @input="getMinutes"
            ></van-field>
        </my-item>
        <my-item label="套餐分钟数">
            <span slot="label-right">分钟数区间范围【{{ currMeal && `${currMeal.meal_start_minutes}-${currMeal.meal_end_minutes}` }}】分钟</span>
            <van-field v-model="modelData.callin_minutes" disabled></van-field>
        </my-item>
        <my-item label="套餐总计费用（元）">
            <van-field :value="(modelData.callin_min_fee * modelData.period).toFixed(2)" disabled></van-field>
        </my-item>
    </div>
</template>

<script>
    import {MyItem, MySelectPicker} from "@/components/MyPlugins";
    import RadioButton from "@/components/RadioButton";
    import {mapActions, mapState} from "vuex";
    import {getDeductTypeList} from "@/api/openOrder";

    export default {
        inheritAttrs: false,
        name: "Step5",
        components: {MyItem, MySelectPicker, RadioButton},
        props: {
            modelData: Object
        },
        data() {
            return {
                deducts: [],
                currMeal: null
            }
        },
        computed: {
            ...mapState('number', ['mealType']),
            ...mapState('openOrder', ['meals']),
            mealOptions() {
                if (this.meals.length === 0) return [];

                return this.meals.map(item => {
                    return {
                        value: item.meal_code,
                        text: item.meal_name
                    }
                });
            },
            // 售价区间
            feeRange() {
                if (!this.currMeal) return null;

                return {min: Number(this.currMeal.meal_start_fee), max: Number(this.currMeal.meal_end_fee)}
            },
            // 资费区间
            priceRange() {
                if (!this.currMeal) return null;

                return {min: Number(this.currMeal.meal_start_price), max: Number(this.currMeal.meal_end_price)}
            },
        },
        mounted() {
            let {callin_meal_code} = this.modelData;
            this.getCurrMeal(callin_meal_code);
            getDeductTypeList().then(res => {
                let {deductTypeList} = res.data;
                this.deducts = deductTypeList.map(item => {
                    return {
                        text: item.deductName,
                        value: String(item.deductType)
                    }
                });
            });
        },
        methods: {
            ...mapActions('openOrder', ['getMeals']),
            onBlur() {
                let {callin_min_fee, callin_price} = this.modelData;
                this.$nextTick(() => {
                    if (this.feeRange && callin_min_fee) {
                        this.modelData.callin_min_fee = this.getRangeValue(callin_min_fee, this.feeRange);
                    }
                    if (this.priceRange && callin_price) {
                        this.modelData.callin_price = this.getRangeValue(callin_price, this.priceRange);
                    }
                });
            },
            getRangeValue(value, range) {
                let {min, max} = range;
                if (value <= min) {
                    value = min;
                } else if (value >= max) {
                    value =  max;
                }

                return value;
            },
            checkMinutes() {
                let {callin_min_fee, callin_price, callin_minutes} = this.modelData;
                let {meal_end_minutes} = this.currMeal;
                return new Promise(resolve => {
                    if (callin_min_fee && callin_price && (Number(callin_minutes) > Number(meal_end_minutes))) {
                        this.$toast.fail('套餐分钟数不能超过最大值，请重新设置售价或资费');
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                });
            },
            // 输入合约期
            onInputTreaty(value) {
                if (value && this.currMeal) {
                    let {meal_period} = this.currMeal;
                    // 计算周期月数
                    this.modelData.treaty_month = value * meal_period;
                }
            },
            onMealSelect(value) {
                this.modelData.callin_min_fee = '';
                this.modelData.callin_price = '';
                this.modelData.callin_minutes = '';
                this.modelData.period = '';
                this.modelData.treaty_month = '';
                this.currMeal = null;
                this.$nextTick(() => this.getCurrMeal(value));
            },
            getCurrMeal(value) {
                if (!value) return;
                let meal = this.meals.find(v => v.meal_code == value);
                if (meal) {
                    this.currMeal = meal;
                } else {
                    // 所选套餐已下线，清空费用信息
                    this.$toast.fail('当前所选呼入套餐已下线，请重新选择！');
                    this.modelData.callin_meal_code = '';
                    this.modelData.callin_min_fee = '';
                    this.modelData.callin_price = '';
                    this.modelData.callin_minutes = '';
                }
            },
            // 获取套餐
            getMealList() {
                this.currMeal = null;
                this.modelData.callin_meal_code = '';
                let {number, meal_type, deduct_type} = this.modelData;
                if (meal_type && deduct_type) {
                    this.getMeals({batchName: number, mealType: meal_type, deductType: deduct_type});
                }
            },
            isValid(value) {
                return value && !isNaN(value) && Number(value);
            },
            // 计算分钟数
            getMinutes() {
                let {callin_min_fee, callin_price} = this.modelData;
                if (this.isValid(callin_min_fee) && this.isValid(callin_price)) {
                    // 向上取整
                    this.modelData.callin_minutes = Math.ceil(callin_min_fee / callin_price);
                }
            },
        }
    }
</script>

<style scoped>

</style>
