<template>
    <div class="Step1">
        <my-item label="企业名称" required>
            <div v-if="$refs.info && $refs.info.item"
                 slot="label-right"
                 style="color: #EE0A24;"
            >
                <i class="iconfont icon-gantanhao1" style="font-size: 12px;"></i>
                <span style="margin-right: 10px;"> 该企业{{ $refs.info.item.status }}</span>
                <i class="iconfont icon-shijian" style="font-size: 12px;"></i>
                <span> {{ $refs.info.item.updateDate }}</span>
            </div>
            <company-info ref="info"
                          v-model="modelData.user_info.company_name"
                          :rules="[{required: true}]"
                          placeholder="请输入企业名称，支持模糊查询"
                          @select="onSelectCompany"
            />
        </my-item>
        <my-item label="统一社会信用代码" required>
            <van-field v-model="modelData.user_info.company_number"
                       v-limit
                       :disabled="modelData.user_info.audit_status == 4"
                       :rules="[{required: true}]"
                       placeholder="请输入统一社会信用代码"
            />
        </my-item>
        <my-item label="注册资金" required>
            <van-field v-model="modelData.user_info.company_reg_money"
                       :rules="[{required: true}]"
                       placeholder="请输入注册资金"
            />
        </my-item>
        <my-item label="企业所在地区" required>
            <my-cascader v-if="areaList && areaList.length > 0"
                         v-model="modelData.user_info.company_location"
                         :field-names="fieldNames"
                         :options="areaList"
                         :rules="[{required: true}]"
                         clearable
                         placeholder="请选择企业所在地区"
            ></my-cascader>
        </my-item>
        <my-item label="企业注册地址" required>
            <van-field v-model="modelData.user_info.company_address"
                       v-limit
                       :rules="[{required: true}]"
                       placeholder="请输入企业注册地址"
            />
        </my-item>
        <my-item label="企业电话" required>
            <van-field v-model="modelData.user_info.company_mobile"
                       v-limit
                       :rules="[{required: true}, {validator: mobileValidator, message: '请输入正确的大陆手机/固话号码'}]"
                       placeholder="请输入企业电话"
            />
        </my-item>
        <my-item label="企业邮箱" required>
            <van-field v-model="modelData.user_info.company_email"
                       v-limit:email
                       :rules="[{required: true}, {validator: emailValidator, message: '请输入正确的企业邮箱'}]"
                       placeholder="请输入企业邮箱"
            />
        </my-item>
        <my-item label="行业类型" required>
            <my-tree-select v-if="industry && industry.length > 0"
                            v-model="modelData.user_info.company_industry"
                            :items="industry"
                            :rules="[{required: true}]"
                            placeholder="请选择行业类型"
            />
        </my-item>
        <my-item label="人员规模" required>
            <radio-button v-model="modelData.user_info.company_scope" :items="companyScope"></radio-button>
        </my-item>
        <my-item required>
            <div slot="label">
                <span style="color: #F56C6C;">* </span>上传营销执照
                <div style="color: #ADAEAB; font-size: 12px; margin-top: 4px; line-height: 18px;">
                    支持JPG/PNG/JPEG格式，且不能大于5MB
                </div>
            </div>
            <my-upload v-model="modelData.user_info.company_img"
                       :disabled="!modelData.user_code"
                       :params="{type: 1, userCode: modelData.user_code}"
            />
        </my-item>
        <my-item label="营业执照有效期" required>
            <van-row align="center" gutter="8" type="flex">
                <van-col span="9">
                    <my-date v-model="modelData.user_info.company_start_time"
                             :rules="[{required: true}]"
                             placeholder="请输入开始时间"
                    />
                </van-col>
                <van-col span="2" style="text-align: center;">至</van-col>
                <van-col span="9">
                    <my-date v-model="modelData.user_info.company_end_time"
                             :disabled="modelData.user_info.company_end_time_forever == '1'"
                             :rules="[{required: modelData.user_info.company_end_time_forever == '2'}]"
                             placeholder="请输入结束时间"
                    />
                </van-col>
                <van-col span="4">
                    <van-switch v-model="modelData.user_info.company_end_time_forever"
                                active-value="1"
                                inactive-value="2"
                    />
                </van-col>
            </van-row>
        </my-item>
        <my-item label="经营范围" required>
            <van-field v-model="modelData.user_info.company_project" placeholder="请输入经营范围" type="textarea" />
        </my-item>
    </div>
</template>

<script>
    import {MyTreeSelect, MyDate, MyItem, MyUpload, MyCascader} from "@/components/MyPlugins";
    import {mapState} from "vuex";
    import RadioButton from "@/components/RadioButton";
    import CompanyInfo from "./CompanyInfo";
    import {snakeCase} from "lodash";
    import {addressOpts, industryOpts} from "@/api/openOrder";
    import {regex} from "@/utils/tools";
    import {EMAIL_REG, MOBILE_REG, TEL_REG} from "@/utils/keys";

    export default {
        inheritAttrs: false,
        name: "Step1",
        components: {MyTreeSelect, MyDate, MyItem, MyUpload, MyCascader, RadioButton, CompanyInfo},
        props: {
            modelData: Object
        },
        data() {
            return {
                // 邮箱验证
                emailValidator: regex([EMAIL_REG]),
                mobileValidator: regex([MOBILE_REG, TEL_REG]),
                fieldNames: {
                    text: 'label',
                    value: 'value',
                    children: 'children',
                },
                options: [],
                areaList: [],
                industry: []
            }
        },
        computed: {
            ...mapState('number', ['companyScope'])
        },
        mounted() {
            addressOpts().then(res => {
                this.areaList = res.data;
            });
            industryOpts().then(res => {
                this.industry = res.data;
            });
        },
        methods: {
            onSelectCompany(item) {
                Object.keys(item).forEach(key => {
                    let field = snakeCase(key);
                    if (typeof this.modelData.user_info[field] !== 'undefined' && item[key]) {
                        this.modelData.user_info[field] = item[key];
                    }
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
    .Step1 {
        &__time {
            display: flex;
            align-items: center;
            gap: 0 8px;
            .van-switch {
                flex: 1;
            }
        }
    }
</style>
